import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { StaticImage } from "gatsby-plugin-image"
import Slider from "react-slick";
import "../styles/pages/music.scss"

const MusicPage = ({ transitionStatus }) => {
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const iframeStyle = { border: 0, marginTop: '16px', boxSizing: 'border-box', marginBottom: '20px', display: 'block', width: '320px', height: '52px' }
  const thumbHeight = 480;

  const images = [
    {
      href: 'https://www.joyfulnoiserecordings.com/products/emerald-sea',

      title: 'VIP Edition + Lathe Cut',

      thumb: <StaticImage
        alt=""
        className=''
        // width={thumbWidth}
        height={thumbHeight}
        objectFit={`contain`}
        src={
          "../images/album-mockups/lathe.jpg"
        }
        formats={["auto"]}
      />
    },
    {
      href: 'https://www.joyfulnoiserecordings.com/products/emerald-sea',
      title: 'Sea Foam',

      thumb: <StaticImage
        alt=""
        className=''
        // width={thumbWidth}
        height={thumbHeight}

        objectFit={`contain`}
        src={
          "../images/album-mockups/coke.jpg"
        }
        formats={["auto"]}
      />
    },
    {
      href: 'https://www.joyfulnoiserecordings.com/products/emerald-sea',
      title: 'CD',

      thumb: <StaticImage
        alt=""
        className=''
        // width={thumbWidth}
        height={thumbHeight}

        objectFit={`contain`}
        src={
          "../images/album-mockups/cd.jpg"
        }
        formats={["auto"]}
      />
    },

    {
      href: 'https://www.joyfulnoiserecordings.com/products/emerald-sea',

      title: 'Cassette',

      thumb: <StaticImage
        alt=""
        className=''
        // width={thumbWidth}
        height={thumbHeight}
        objectFit={`contain`}
        src={
          "../images/album-mockups/cassette.jpg"
        }
        formats={["auto"]}
      />
    },
  ]

  const videoOutput =
    images.map((video, index) => {
      return (
        <a href={video.href} className="slide" key={index} target="_blank" rel="nofollow noreferrer">
          {video.thumb}
          <div className="info">
            <Typography component="p" variant="body2" color="primary.dark">
              {video.title}
            </Typography>
          </div>
        </a>
      )
    })

  return (
    <Layout currentPage={`music`}>
      <Seo title="Music" />
      <Container className={'music-page'} maxWidth={false} >
        <div className="left">
          <div className="img-parent mobile">
            <StaticImage
              alt=""
              className='mobile'
              height={thumbHeight}
              objectFit={`contain`}
              src={"../images/album.jpeg"}
              formats={["auto"]}
            />
          </div>

          <Typography component="h1" variant="h1" color="primary.dark">
            Emerald Sea
          </Typography>
          <Typography variant="body1" color="primary.dark">
            Rendered in dynamic, ambitious orchestral passages, it forms the basis of future stage performances intended to draw its half-submerged narrative into the visual sphere. In its dreamlike impressions, it could also be the soundtrack to a long-forgotten early musical film - an experience that delighted and transported audiences and then vanished from record, surviving only in the imprint of memory.
          </Typography>

          <iframe
            title="The Glare"
            style={iframeStyle}
            src="https://bandcamp.com/EmbeddedPlayer/album=1836295179/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true/" seamless>
            <a href="https://soundofceres.bandcamp.com/album/emerald-sea">Emerald Sea by Sound of Ceres</a>
          </iframe>
          <a
            href="https://www.joyfulnoiserecordings.com/products/emerald-sea"
            className="button dark"
            target="_blank"
            rel="noreferrer nofollow"
          >
            <Typography variant="button" color="primary.light">
              Order
            </Typography>
          </a>
        </div>

        <div className="right">
          <Slider {...settings}>
            {videoOutput}
          </Slider>
        </div>
        {/* Mobile image */}
        <StaticImage
          style={{
            gridArea: "1/1",
            // You can set a maximum height for the image, if you wish.
            // maxHeight: 600,
          }}
          layout="fullWidth"
          alt=""
          src={"../images/album.jpeg"}
        />
      </Container>
    </Layout>
  )
}

export default MusicPage
